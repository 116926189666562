<template>
    <div class="home">
        <div class="white-bg">
            <div class="zpanle adjust-cont">
                <div class="baccount-tit mb-20">
                    <h3 class="g-title" style="margin-top: -20px;">账户安全</h3>
                </div>
                <div class="panner-bd asafe-bd asafe_bd" style="padding:0 0 40px">
                    <div class="asafe-list">
                        <div class="safe-tips">安全提醒: 请妥善保管密码！我们的工作人员不会以任何理由向您索取密码。</div>
                        <el-row :gutter="20">
                            <el-col :span="3">
                                <div class="grid-content tit-label">更改邮箱</div>
                            </el-col>
                            <el-col :span="21">
                                <div class="grid-content account-txt">
                                    <div class="txt content" v-if="userInfo.info.email == ''">
                                        <span class="cl-red">您还未绑定邮箱</span>
                                        <a
                                            href="javascript:void(0)"
                                            class="link-act"
                                            @click="gotoValidPassword('绑定邮箱','userSettingE-mail')"
                                        >绑定</a>
                                    </div>
                                    <div class="txt content" v-if="userInfo.info.email != ''">
                                        <span
                                            class="cl-red"
                                        >您当前的邮箱是&nbsp;&nbsp;{{ userInfo.info.email | encryptedmailbox }}</span>
                                        <a
                                            href="javascript:void(0)"
                                            class="link-act"
                                             @click="gotoValidPassword('修改邮箱','userSettingE-mail')"
                                        >修改</a>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="3">
                                <div class="grid-content tit-label">换绑手机</div>
                            </el-col>
                            <el-col :span="21">
                                <div class="grid-content account-txt">
                                    <div class="txt content">
                                        <span>您当前的手机号码是&nbsp;&nbsp;{{ userInfo.info.mobile |encryptedmPhoneNum }}</span>
                                        <a
                                            href="javascript:void(0)"
                                            class="link-act"
                                            @click="gotoValidPassword('换绑手机','userSettingModify-mobile')"
                                        >更改</a>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="3">
                                <div class="grid-content tit-label">更改密码</div>
                            </el-col>
                            <el-col :span="21">
                                <div class="grid-content account-txt">
                                    <div class="content">
                                        <!-- <span>上次更改登录密码时间 2019年04月29日 15:18:02 ，建议您90天更改一次登录密码</span> -->
                                        <span>建议您90天更改一次登录密码</span>
                                        <a
                                            href="javascript:void(0)"
                                            class="link-act"
                                            @click="gotoValidPassword('更改登录密码','userSettingPassword')"
                                        >修改</a>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row :gutter="20">
                            <el-col :span="3">
                                <div class="grid-content tit-label">支付密码</div>
                            </el-col>
                            <el-col :span="21">
                                <div class="grid-content account-txt">
                                    <div
                                        class="txt content"
                                        v-if="!userInfo.info.has_pay_password || userInfo.info.has_pay_password == 0"
                                    >
                                        <span class="cl-red">您还未设置支付密码，请尽快设置</span>
                                        <a
                                            href="javascript:void(0)"
                                            class="link-act"
                                            @click="gotoValidPassword('设置支付密码','userSettingPassword',1)"
                                        >设置</a>
                                    </div>
                                    <div class="content" v-if="userInfo.info.has_pay_password == 1">
                                        <span>您已设置支付密码，可点击右侧按钮进行更换</span>
                                        <a
                                            href="javascript:void(0)"
                                            class="link-act"
                                            @click="gotoValidPassword('修改支付密码','userSettingPassword',2)"
                                        >修改</a>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <!-- 设置后的内容 -->
                        <!-- <el-row :gutter="20">
                        <el-col :span="2"><div class="grid-content tit-label">支付密码</div></el-col>
                        <el-col :span="18">
                            <div class="grid-content account-txt">
                                <p v-if="userInfo.info.has_pay_password == 1"><i class="pswd">&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</i><a href="javascript:void(0)" class="link-act" >修改密码</a></p>
                            </div>
                        </el-col>
                        </el-row>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "accountSafe",
    metaInfo() {
        return {
            title: "账户安全 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {};
    },
    methods: {
        gotoValidPassword(title,routerName,type){ // 跳转密码验证，修改前先验证登录密码，输入正确后才可进行修改
             this.$router.push({ // 跳转到验证密码页面
                 name: 'validpassword',
                 query: {
                     type: type|| '', // 如果用着相同的页面，可以用此参数来控制是否显示某些字段，如设置支付密码的type为1，不需要验证手机，修改支付密码为2，则需要验证手机,默认不传为设置登录密码
                     routerName: routerName, // 以备到密码验证通过后，需要跳转的页面的路由名称
                     title: title || '', // 需要到内页显示的标题，如:更改邮箱，换绑手机，更改密码，支付密码更改等
                 }
             });
        },
        ...mapActions("userInfo", ["ajaxUserInfo"])
    },
    created() {
        // this.ajaxUserInfo();
    },
    computed: {
        ...mapState(["userInfo"])
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
</style>


